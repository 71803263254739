import React, { useState, useRef, useEffect } from "react";

// Custom Slider Component
const CustomSlider = ({ min = 10, max = 80, step = 10, value, setValue }) => {
  const sliderRef = useRef(null);

  const updateValue = (clientX) => {
    if (!sliderRef.current) return;

    const { left, width } = sliderRef.current.getBoundingClientRect();
    let newValue = min + ((clientX - left) / width) * (max - min);

    // Snap to nearest step
    newValue = Math.round(newValue / step) * step;
    newValue = Math.max(min, Math.min(max, newValue));

    setValue(newValue);
  };

  // Handle Click on Track
  const handleTrackClick = (e) => updateValue(e.clientX);

  // Handle Mouse Events
  const handleMouseMove = (e) => updateValue(e.clientX);
  const handleMouseDown = (e) => {
    updateValue(e.clientX);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };
  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // Handle Touch Events
  const handleTouchMove = (e) => updateValue(e.touches[0].clientX);
  const handleTouchStart = (e) => {
    updateValue(e.touches[0].clientX);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
  };
  const handleTouchEnd = () => {
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("touchend", handleTouchEnd);
  };

  return (
    <div className="flex items-center justify-center rounded-xl border border-red-600 h-10 px-5 bg-[#1a0000]">
      <div
        className="relative w-full h-10 p-2"
        ref={sliderRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onClick={handleTrackClick}
      >
        {/* Slider Track */}
        <div className="absolute top-1/2 left-0 w-full h-2 bg-transparent transform -translate-y-1/2 flex justify-between">
          {[...Array((max - min) / step + 1)].map((_, index) => (
            <div
              key={index}
              className="w-2 h-2 bg-red-100 rounded-full opacity-75 cursor-pointer"
            />
          ))}
        </div>

        {/* Slider Thumb */}
        <div
          className="absolute top-1/2 transform -translate-y-1/2 -translate-x-1/2 w-10 h-8 bg-red-600 rounded-xl flex items-center justify-center text-white text-sm font-bold cursor-pointer"
          style={{
            left: `${((value - min) / (max - min)) * 100}%`,
            transition: "left 0.3s ease-in-out", // Smooth movement transition
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );
};

const SavingsCalculator = () => {
  const [fleetSize, setFleetSize] = useState(10);
  const fuelSavingPerVehicle = 676;
  const perVehicleAnnualCost = 14263.6;
  const [annualCost, setAnnualCost] = useState(0);
  const [totalFuelSaving, setTotalFuelSaving] = useState(0);
  const [fraudReduction, setFraudReduction] = useState(0);
  const [totalSaving, setTotalSaving] = useState(0);

  useEffect(() => {
    setFleetSize(fleetSize); // Updating the fleet size from the slider
    const annualCostCalc = fleetSize * perVehicleAnnualCost;
    const totalFuelSavingCalc = fleetSize * fuelSavingPerVehicle;
    const fraudReductionCalc = annualCostCalc * 0.04;
    const totalSavingCalc = totalFuelSavingCalc + fraudReductionCalc;

    setAnnualCost(annualCostCalc);
    setTotalFuelSaving(totalFuelSavingCalc);
    setFraudReduction(fraudReductionCalc);
    setTotalSaving(totalSavingCalc);
  }, [fleetSize]);

  const formatNumber = (value) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <>
      <section className="relative h-auto overflow-hidden flex items-start z-10 w-full bg-black mt-10">
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full mx-auto px-3">
          <div className="w-full flex lg:flex-row flex-col gap-5 justify-between mx-auto rounded-3xl bg-dark_brown lg:p-10 p-3">
            <div className="lg:w-1/2 md:w-1/2 w-full flex flex-col items-start">
              <span className="text-white text-sm font-normal border border-white rounded-xl px-3 py-1 lg:mb-8 mb-4">
                Savings
              </span>
              <h1 className="lg:text-3xl text-xl">
                How Much Could You Be Saving?
              </h1>
              <div className="mt-5">
                <img
                  src={
                    process.env.REACT_APP_BASE_URL +
                    `/assets/images/saving-bg.png`
                  }
                />
              </div>
            </div>
            <div className="lg:w-1/2 md:w-1/2 w-full bg-black/30 rounded-lg py-12 px-8">
              <div className="flex items-center lg:flex-row flex-col gap-2 mb-10">
                <p className="text-center text-dark-red lg:w-24 w-full">
                  Fleet Size
                </p>
                <div className="w-full">
                  <CustomSlider
                    min={10}
                    max={80}
                    step={10}
                    value={fleetSize}
                    setValue={setFleetSize}
                  />
                </div>
              </div>
              <div className="w-full flex gap-5 mb-10">
                <div className="w-1/2 bg-dark-red/10 rounded-lg text-center py-2 px-5 shadow-md">
                  <p className="text-dark-red text-sm">Savings From Fraud</p>
                  <p className="text-xl font-semibold">
                    {/* ${fraudReduction.toFixed(2)} */}$
                    {formatNumber(fraudReduction)}
                  </p>
                </div>
                <div className="w-1/2 bg-dark-red/10 rounded-lg text-center py-2 px-5 shadow-md">
                  <p className="text-dark-red text-sm">Fuel Savings</p>
                  <p className="text-xl font-semibold">
                    {/* ${totalFuelSaving.toFixed(2)} */}$
                    {formatNumber(totalFuelSaving)}
                  </p>
                </div>
              </div>
              <div className="w-full flex">
                <div className="w-full bg-dark-red/10 rounded-lg text-center py-2 px-5 border border-dark-red">
                  <p className="text-white text-sm">Total Savings</p>
                  <p className="text-3xl font-semibold">
                    ${formatNumber(totalSaving)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SavingsCalculator;
