import React from "react";
import Banner from "../../common-sections/Banner";
import ClientReview from "../../common-sections/ClientReview";
// import ContactUs from "../../common-sections/ContactUsOld";
import VideoSec from "../../common-sections/VideoSec";
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import AboutUs from "../../common-sections/AboutUs";
import Features from "../../common-sections/Features";
import SavingsCalculator from "../../common-sections/SavingsCalculator";
import HowItWorks from "../../common-sections/HowItWorks";
import Accordion from "../../common-sections/Accordion";
import ContactUs from "../../common-sections/ContactUs";

gsap.registerPlugin(ScrollTrigger);

const HomepageView = () => {
  const reviewData = [
    {
      desc: "Since moving to Piston from Wex, not only have we seen a marked decrease in gallons used, we are also averaging 4% in savings! We are saving $1000’s every week!",
      username: "Alex",
      designation: "Owner, Camil Express",
    },
    {
      desc: "Not only does Piston ensure that my drivers go to the right station, they deliver in substantial savings for us. Their QR system means we do not have to keep a track of physical cards anymore, saving us countless headaches.",
      username: "Henry E",
      designation: "Owner, Purple Express",
    },
    {
      desc: "We earned a measly 1.5% cash back on our credit card with ZERO fraud control. With Piston, we are averaging over 4%",
      username: "Marcello D",
      designation: "Owner, MRD",
    },
    {
      desc: "I thought we were getting the cheapest gas with Fleetcor. Piston not only beat them, but our reporting now takes seconds instead of hours trying to reconcile which driver filled up what vehicle on what day of the week.",
      username: "Robson R",
      designation: "Owner, Apollo Transport",
    },
    {
      desc: "Since moving to Piston from Wex, not only have we seen a marked decrease in gallons used, we are also averaging 4% in savings! We are saving $1000’s every week!",
      username: "Alex",
      designation: "Owner, Camil Express",
    },
    {
      desc: "Not only does Piston ensure that my drivers go to the right station, they deliver in substantial savings for us. Their QR system means we do not have to keep a track of physical cards anymore, saving us countless headaches.",
      username: "Henry E",
      designation: "Owner, Purple Express",
    },
    {
      desc: "We earned a measly 1.5% cash back on our credit card with ZERO fraud control. With Piston, we are averaging over 4%",
      username: "Marcello D",
      designation: "Owner, MRD",
    },
    {
      desc: "I thought we were getting the cheapest gas with Fleetcor. Piston not only beat them, but our reporting now takes seconds instead of hours trying to reconcile which driver filled up what vehicle on what day of the week.",
      username: "Robson R",
      designation: "Owner, Apollo Transport",
    },
  ];

  const accordionItems = [
    {
      title: "How do I integrate PistonPay at my gas station?",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque expedita doloribus fugit quas quo sunt obcaecati suscipit eius facere laudantium omnis molestiae, pariatur vel quibusdam laborum. Quo et beatae nihil!",
    },
    {
      title: "How will I receive payments?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      title: "What are the transaction fees?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];

  const cardData = [
    {
      img: `/assets/images/work1.png`,
      title: "Drivers Fuel Up",
      desc: "Scan QR code to purchase fuel.",
    },
    {
      img: `/assets/images/work2.png`,
      title: "Automated Fraud Protection",
      desc: "Transactions are monitored in real-time.",
    },
    {
      img: `/assets/images/work3.png`,
      title: "Rebates Applied Instantly ",
      desc: "Fleets get savings at partner stations.",
    },
  ];

  return (
    <>
      <Banner
        title={
          <h1
            className="xl:text-7xl md:text-4xl text-2xl font-medium text-white"
            style={{ lineHeight: "1.25" }}
          >
            The Smarter Way
            <br /> to Fuel Your Fleet
          </h1>
        }
        desc={
          <p className="lg:text-2xl text-base font-medium mt-5 text-white">
            Reduce fuel fraud, maximize savings, and <br /> simplify fleet
            management with PistonPay.
          </p>
        }
        image={`assets/images/banner.png`}
      />

      <Features numberOfItems={3} />

      <AboutUs />

      <HowItWorks
        title="How it works"
        cardData={cardData}
        className="mx-auto"
      />

      <SavingsCalculator />

      <VideoSec src="https://www.youtube.com/embed/aAYX48oRtgw?si=_364SJe6e7LGFHVC" />

      <ClientReview
        reviewData={reviewData}
        title={
          <h2 className="lg:text-3xl text-xl text-center">Testimonials</h2>
        }
      />
      <ContactUs />
      {/* <Accordion items={accordionItems} /> */}
    </>
  );
};

export default HomepageView;
