import React from "react";
import { BsLightningChargeFill } from "react-icons/bs";
import { motion } from "framer-motion";

const Banner = ({ title, desc, image }) => {
  return (
    <>
      <section
        className="relative h-auto banner-bg overflow-hidden flex items-start z-10 w-full bg-black"
        id="home-banner"
      >
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full mx-auto px-3">
          <div className="w-full flex flex-wrap items-center justify-center">
            <div className="w-full lg:pt-36 pt-28 text-center">
              {title}
              {desc}
              <div className="flex lg:gap-8 gap-4 lg:mt-12 mt-10 justify-center">
                <a
                  href="#contact-form"
                  className="text-white bg-dark-red hover:bg-[#9a1c1f] rounded-xl lg:text-base text-sm font-normal px-3 lg:py-2 py-2 text-center flex items-center"
                >
                  <BsLightningChargeFill className="mr-2" />
                  Request a Demo
                </a>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="text-center"
            >
              <div className="w-full lg:mt-16 mt-10">
                <img
                  src={process.env.REACT_APP_BASE_URL + image}
                  alt=""
                  className="w-full"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
