import React from "react";
import { motion } from "framer-motion";

const HowItWorks = ({ numberOfItems, title, cardData, className }) => {
  const url = process.env.REACT_APP_BASE_URL;

  return (
    <>
      <section className="relative h-auto overflow-hidden flex items-start z-10 w-full bg-black lg:py-16 py-10">
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full mx-auto px-3">
          <div className="flex flex-wrap flex-col justify-between mx-auto rounded-3xl space-y-5">
            <div className="w-full text-center">
              <h1 className="lg:text-3xl text-xl">{title}</h1>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-col-auto gap-3">
              {cardData.slice(0, numberOfItems).map((item, index) => (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: index * 0.3 }}
                  viewport={{ once: true }}
                  key={item.title}
                >
                  <div className="transition-all duration-150 bg-dark_brown rounded-3xl text-center ps-5 pt-5">
                    <span className="text-sm border border-white rounded-xl lg:px-3 px-4 py-1">
                      Step {index + 1}
                    </span>

                    <div className="flex flex-col mt-3">
                      <h4 className="text-white lg:text-2xl text-lg font-normal w-full">
                        {item.title}
                      </h4>
                      <p className="mb-5 text-white text-opacity-60 text-base">
                        {item.desc}
                      </p>
                    </div>
                    <div className="text-center">
                      <img
                        src={url + item.img}
                        alt=""
                        className={`w-auto ${className}`}
                      />
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
