import React from "react";
import { useLocation } from "react-router-dom";

const VideoSec = ({ src, title, index, paddingTop }) => {
  const titleArr = title?.split(" ");
  const pathName = useLocation();

  return (
    <>
      <div className={`w-full bg-black px-5 ${paddingTop}`}>
        <h2 className="lg:text-4xl md:text-2xl text-xl font-medium text-center">
          {titleArr?.map((word, idx) => (
            <React.Fragment key={idx}>
              {idx === index ? (
                <span className="text-dark-red shadow-red relative">
                  {word}
                </span>
              ) : (
                word
              )}
              {idx === index && <br />}{" "}
              {/* Insert <br /> after the highlighted word */}
              {idx < titleArr.length - 1 && " "}
            </React.Fragment>
          ))}
        </h2>
      </div>

      <section
        className={`relative w-full lg:pt-28 pt-10 lg:pb-16 pb-8 py-5 video-sec overflow-hidden bg-black`}
      >
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full mx-auto px-3">
          <div className="w-full rounded-3xl flex flex-wrap flex-col justify-between mx-auto space-y-5 border border-dark-red">
            <div className="flex justify-center w-full border border-gray-custom rounded-3xl">
              <iframe
                className="rounded-3xl"
                width="100%"
                src={src}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;compute-pressure"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoSec;
