import React from "react";
import { motion } from "framer-motion";

const Features = ({ numberOfItems }) => {
  const url = process.env.REACT_APP_BASE_URL;
  const cardData = [
    {
      img: url + `/assets/images/feature1.png`,
      title: "Fuel Savings",
      desc: "Industry leading savings of upto 40 Cents off/gallon",
    },
    {
      img: url + `/assets/images/feature2.png`,
      title: "Cardless Solution",
      desc: "Piston's cardless technology frees you from the hassles of card management.",
    },
    {
      img: url + `/assets/images/feature3.png`,
      title: "Fraud Protection",
      desc: "Leverage Piston's proprietary fraud prevention engine to eliminate fraudulent spend.",
    },
  ];

  return (
    <>
      <section className="relative h-auto overflow-hidden flex items-start z-10 w-full bg-black mt-14">
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm  w-full mx-auto px-3">
          <div className="bg-dark_brown w-full flex flex-wrap flex-col justify-between mx-auto rounded-3xl space-y-10 px-5 pt-10 pb-20 lg:px-10">
            <div className="w-full">
              <span className="lg:px-3 px-4 py-1 border border-white rounded-xl text-sm">
                {" "}
                Features{" "}
              </span>
            </div>{" "}
            <h1 className="lg:text-3xl text-xl">
              Your Trusted Partner for Seamless <br /> Fleet Management and
              Support
            </h1>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-col-auto lg:gap-20 gap-12">
              {cardData.slice(0, numberOfItems).map((item, index) => (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  viewport={{ once: true }}
                  key={item.title}
                >
                  <div className="transition-all duration-150 flex gap-5">
                    <img
                      src={item.img}
                      alt=""
                      className="h-8 w-8 object-contain"
                    />
                    <div className="flex flex-col lg:space-y-4 space-y-2">
                      <h4 className="text-white lg:text-2xl text-lg font-normal w-full">
                        {item.title}
                      </h4>
                      <p className="mb-5 text-white text-opacity-60 text-base">
                        {item.desc}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
