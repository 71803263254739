import React from "react";
import Accordion from "../../common-sections/Accordion";
// import ContactUs from "../../common-sections/ContactUsOld";
import { useState } from "react";
import { useEffect } from "react";
import VideoSec from "../../common-sections/VideoSec";
import ClientReview from "../../common-sections/ClientReview";
import Banner from "../../common-sections/Banner";
import PartnerWithUs from "../../common-sections/PartnerWithUs";
import AboutUs from "../../common-sections/AboutUs";
import HowItWorks from "../../common-sections/HowItWorks";
import ContactUs from "../../common-sections/ContactUs";

const GasStationView = ({ item, isOpen, onClick }) => {
  const [isMobile, setIsMobile] = useState(false);
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust the width as needed
  };
  useEffect(() => {
    checkMobile(); // Check on component mount

    // Add event listener for window resize
    window.addEventListener("resize", checkMobile);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const reviewData = [
    {
      desc: "PistonPay added 20,000 gallons per month to my sales! All at no cost to me.",
      username: "Paul S",
      designation: "Owner, One Stop Fuel",
    },
    {
      desc: "Piston’s team is very easy to work with. Not only did they deliver on the additional revenue, their customer support is always there to help us out, and answer any questions. Their dashboard makes reconciliation very easy!",
      username: "Ramesh S",
      designation: "Owner, Parkway Express",
    },
    {
      desc: "Joining the PistonPay network has resulted in meaningful increase in our gas sales, as well as our C Store sales. The iPad is very easy to use and train our team on.",
      username: "Richard M",
      designation: "Regional Manager, Red Square",
    },
    {
      desc: "PistonPay added 20,000 gallons per month to my sales! All at no cost to me.",
      username: "Paul S",
      designation: "Owner, One Stop Fuel",
    },
    {
      desc: "Piston’s team is very easy to work with. Not only did they deliver on the additional revenue, their customer support is always there to help us out, and answer any questions. Their dashboard makes reconciliation very easy!",
      username: "Ramesh S",
      designation: "Owner, Parkway Express",
    },
    {
      desc: "Joining the PistonPay network has resulted in meaningful increase in our gas sales, as well as our C Store sales. The iPad is very easy to use and train our team on.",
      username: "Richard M",
      designation: "Regional Manager, Red Square",
    },
  ];

  const cardData = [
    {
      img: `/assets/images/work-gas1.png`,
      title: "Station Accepts PistonPay",
      desc: "Via QR code or POS integration",
    },
    {
      img: `/assets/images/work-gas2.png`,
      title: "Fleet Drivers Scan & Pay",
      desc: "Secure transaction ensures seamless payments",
    },
    {
      img: `/assets/images/work-gas3.png`,
      title: "Guaranteed Payments",
      desc: "Reduced transaction fees and reliable processing",
    },
  ];

  const accordionItems = [
    {
      title: "How do I integrate PistonPay at my gas station?",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque expedita doloribus fugit quas quo sunt obcaecati suscipit eius facere laudantium omnis molestiae, pariatur vel quibusdam laborum. Quo et beatae nihil!",
    },
    {
      title: "How will I receive payments?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      title: "What are the transaction fees?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];

  return (
    <>
      <Banner
        title={
          <h1
            className="xl:text-7xl md:text-4xl text-2xl font-medium text-white"
            style={{ lineHeight: "1.25" }}
          >
            Boost Your Fuel Sales
            <br /> with PistonPay
          </h1>
        }
        desc={
          <p className="lg:text-2xl text-base font-medium mt-5 text-white">
            Gain new fleet customers, eliminate credit risks, <br /> and reduce
            transaction fees.
          </p>
        }
        image={`assets/images/gas-station-banner.png`}
      />
      <PartnerWithUs numberOfItems={4} />

      <AboutUs />

      <HowItWorks
        title="How it Works for Gas Stations"
        cardData={cardData}
        className="w-full"
      />

      <VideoSec src="https://www.youtube.com/embed/Uwa4VWfJm1Y?si=GNyMRIZ51ZRVIsES" />

      <ClientReview
        reviewData={reviewData}
        title={
          <h2 className="lg:text-3xl text-xl text-center">Testimonials</h2>
        }
      />

      <ContactUs />
      {/* <Accordion items={accordionItems} /> */}
    </>
  );
};

export default GasStationView;
