import React from "react";
import { motion } from "framer-motion";

const PartnerWithUs = ({ numberOfItems }) => {
  const url = process.env.REACT_APP_BASE_URL;
  const cardData = [
    {
      img: url + `/assets/images/partner1.png`,
      title: "Boost your Fuel Sales",
      desc: "Attract local fleets to your station",
    },
    {
      img: url + `/assets/images/partner2.png`,
      title: "Increase Convenience Store Sales",
      desc: "Piston drivers enter the Convenience Store every time they fuel up",
    },
    {
      img: url + `/assets/images/partner3.png`,
      title: "No Credit Risk",
      desc: "Reduce your exposure to default",
    },
    {
      img: url + `/assets/images/partner4.png`,
      title: "Build Loyalty",
      desc: "Build loyalty to your station, without the hassle",
    },
  ];

  return (
    <>
      <section className="relative h-auto overflow-hidden flex items-start z-10 w-full bg-black mt-14">
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full mx-auto px-3">
          <div className="flex flex-wrap flex-col justify-between mx-auto space-y-10">
            <div className="flex justify-center flex-col w-full gap-2">
              <h1 className="lg:text-3xl text-xl text-center">
                Why Partner with PistonPay?
              </h1>
            </div>

            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-col-auto gap-3">
              {cardData.slice(0, numberOfItems).map((item, index) => (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: index * 0.5 }}
                  viewport={{ once: true }}
                >
                  <div
                    className="transition-all duration-150 flex gap-5 bg-dark_brown px-6 py-8 rounded-3xl"
                    key={index}
                  >
                    <img
                      src={item.img}
                      alt=""
                      className="h-8 w-8 object-contain"
                    />
                    <div className="flex flex-col lg:space-y-4 space-y-2">
                      <h4 className="text-white lg:text-2xl text-lg font-normal w-full">
                        {item.title}
                      </h4>
                      <p className="mb-5 text-white text-opacity-60 text-base">
                        {item.desc}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartnerWithUs;
