import React from "react";
import { Link } from "react-router-dom";
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";

const Footer = () => {
  return (
    <>
      <footer className="relative flex items-center z-10 w-full bg-black footer-sec pt-16 overflow-hidden">
        <div className="w-full">
          <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full px-3 xl:px-0 flex flex-wrap items-center justify-between mx-auto">
            <div className="grid md:grid-cols-5 w-full">
              <div className="w-full border-r-0 border-gray-600 lg:py-14 md:py-8 py-4 flex flex-col items-start">
                <img
                  src={
                    process.env.REACT_APP_BASE_URL + `/assets/images/logo.png`
                  }
                  alt=""
                  width={100}
                />
                <div className="flex flex-col items-start gap-2 mt-auto">
                  <p>Start Saving Today</p>
                  <a
                    href="#contact-form"
                    className="text-white bg-dark-red hover:bg-[#9a1c1f] rounded-xl text-sm font-normal px-3 lg:py-2 py-2 text-center flex items-center"
                  >
                    Request a Demo
                  </a>
                </div>
              </div>

              <div className="w-full footer-links lg:px-10 md:px-5 px-0 border-r-0 border-gray-600 lg:py-14 md:py-8 py-4">
                <div className="heading mb-4">
                  <h6 className="text-sm text-white text-opacity-50 font-bold">
                    Pages
                  </h6>
                </div>
                <ul className="flex flex-col gap-3 text-white">
                  <li>
                    <Link to="/">Fleets</Link>
                  </li>

                  <li>
                    <Link to="/gas-station">Gas Stations</Link>
                  </li>
                </ul>
              </div>
              <div className="w-full footer-links lg:px-10 md:px-5 px-0 border-r-0 border-gray-600 lg:py-14 md:py-8 py-4">
                <div className="heading mb-4">
                  <h6 className="text-sm text-white text-opacity-50 font-bold">
                    Info
                  </h6>
                </div>

                <ul className="flex flex-col gap-3 text-white">
                  {/* <li>
                    <Link to="/">About us</Link>
                  </li> */}

                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                  <li>
                    <a href="#contact-form">Partner with us</a>
                  </li>
                  <li>
                    <Link to="https://partner.pistonpay.com/" target="_blank">
                      Partner Login
                    </Link>
                  </li>
                  <li>
                    <Link to="https://fleet.pistonpay.com/" target="_blank">
                      Fleet Login
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-full footer-links lg:px-10 md:px-5 px-0 lg:py-14 md:py-8 py-4">
                <div className="heading mb-4">
                  <h6 className="text-sm text-white text-opacity-50 font-bold">
                    Legal
                  </h6>
                </div>
                <ul className="flex flex-col gap-3 text-white">
                  {/* <li>
                    <Link to="/coming-soon">Privacy Policy</Link>
                  </li> */}
                  <li>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                  {/* <li>
                    <Link to="/terms-and-conditions">Privacy</Link>
                  </li> */}
                </ul>
              </div>
              <div className="w-full footer-links lg:px-10 md:px-5 px-0 lg:py-14 md:py-8 py-4">
                <div className="heading mb-4">
                  <h6 className="text-sm text-white text-opacity-50 font-bold">
                    Coming Soon
                  </h6>
                </div>
                <ul className="flex flex-col gap-3 text-white">
                  <li>
                    <Link to="/coming-soon">Blog</Link>
                  </li>
                  <li>
                    <Link to="/coming-soon">Press</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="relative flex items-center z-10 w-full bg-black">
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full px-5 xl:px-0 flex flex-wrap items-center justify-between mx-auto">
          <div className="w-full footer-links py-10 flex justify-between items-center">
            <ul className="flex gap-5">
              <li>Piston. All right reserved. © {new Date().getFullYear()}</li>
            </ul>
            {/* <ul className="flex gap-4 text-white text-opacity-50">
              <li>
                <Link to="/">
                  <BsLinkedin className="text-lg" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <BsFacebook className="text-lg" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <BsInstagram className="text-lg" />
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
