import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const ComingSoon = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 991); // Adjust the width as needed
  };
  useEffect(() => {
    checkMobile(); // Check on component mount

    // Add event listener for window resize
    window.addEventListener("resize", checkMobile);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <>
      <div className="comingsoon-sec relative w-full bg-black min-h-[600px] flex items-center overflow-hidden z-10">
        <section className="w-full mt-24">
          <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full mx-auto px-3">
            <div className="flex justify-center items-center flex-col flex-start w-full gap-5 pt-20">
              {/* <img
                src={process.env.REACT_APP_BASE_URL + `/assets/images/404.png`}
                alt=""
                className="w-1/2"
              /> */}
              <h2 className="md:text-7xl text-xl font-normal text-center mb-4">
                Coming Soon
              </h2>

              <Link
                to="/"
                className="text-white bg-dark-red hover:bg-[#9a1c1f] rounded-xl text-sm font-normal px-3 py-2 text-center flex items-center"
              >
                Return Home
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ComingSoon;
