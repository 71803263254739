import React, { useCallback, useEffect, useRef, useState } from "react";
import ActionButton from "../buttons/ActionButton";
import InputMask from "react-input-mask";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    number: "",
    message: "",
    agreement: false,
  });
  const [errors, setErrors] = useState({});
  const [formTouched, setFormTouched] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verified, setVerified] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  //   const [fields, setFields] = useState(formData);

  const captchaRef = useRef(null);

  //   const validateForm = () => {
  //     const newErrors = {};
  //     if (!formData.name.trim()) {
  //       newErrors.name = "Name is required";
  //     } else if (/\d/.test(formData.name)) {
  //       newErrors.name = "Name cannot contain numbers";
  //     }
  //     if (!formData.companyName.trim())
  //       newErrors.companyName = "Company name is required";

  //     if (
  //       !formData.email.trim() ||
  //       !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
  //     )
  //       newErrors.email = "Valid email is required";

  //     // if (!formData.number.trim().replace(/[^0-9]/g, "") || formData.number.length !== 10)
  //     //     newErrors.number = "Phone number must be 10 digits";

  //     if (!formData.number.trim()) {
  //       newErrors.number = "Phone number is required";
  //     } else if (formData.number.replace(/\D/g, "").length !== 10) {
  //       newErrors.number = "Phone number must be exactly 10 digits";
  //     } else if (formData.number.startsWith("0")) {
  //       newErrors.number = "Phone number cannot start with 0";
  //     }

  //     if (!formData.message.trim()) newErrors.message = "Message cannot be empty";

  //     if (!verified) newErrors.recaptcha = "Please complete the reCAPTCHA";

  //     setErrors(newErrors);
  //     return Object.keys(newErrors).length === 0; // Returns true if no errors
  //   };

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (/\d/.test(formData.name)) {
      newErrors.name = "Name cannot contain numbers";
    }
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company name is required";
    }
    if (
      !formData.email.trim() ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
    ) {
      newErrors.email = "Valid email is required";
    }
    if (!formData.number.trim()) {
      newErrors.number = "Phone number is required";
    } else if (formData.number.replace(/\D/g, "").length !== 10) {
      newErrors.number = "Phone number must be exactly 10 digits";
    } else if (formData.number.startsWith("0")) {
      newErrors.number = "Phone number cannot start with 0";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message cannot be empty";
    }
    if (!verified) {
      newErrors.recaptcha = "Please complete the reCAPTCHA";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, verified]);

  //   const handleInputChange = (e) => {
  //     setFormData({ ...formData, [e.target.id]: e.target.value });

  //     if (formTouched) {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [e.target.id]: undefined,
  //       }));
  //     }
  //   };

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;

    // Avoid unnecessary state updates
    // if (formData[id] !== value) {
    //   setFormData((prevData) => ({ ...prevData, [id]: value }));
    // }
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [id]: checked,
      }));
    } else {
      // Handle other inputs
      if (formData[id] !== value) {
        setFormData((prevData) => ({ ...prevData, [id]: value }));
      }
    }
    // Remove error only if the form is touched

    // // Clear phone number error when touched
    // if (id === "number" && errors.number) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     number: undefined,
    //   }));
    // }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: undefined,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormTouched(true); // Mark the form as submitted
    if (validateForm()) {
      setIsSubmitting(true); // Disable the button
      const token = captchaRef.current.getValue();

      const url = process.env.REACT_APP_API_URL;
      const site_key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
      const bearer_token = process.env.REACT_APP_API_BEARER_TOKEN;

      // console.log(site_key);

      axios
        .post(
          url,
          {
            name: formData.name,
            company_name: formData.companyName,
            email: formData.email,
            phone: formData.number,
            message: formData.message,
          },
          {
            headers: {
              Authorization: "Bearer " + bearer_token,
            },
          }
        )
        // .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));

      await sendEmail(formData);
      clearForm();
    }
  };

  const clearForm = () => {
    setSuccessMessage("Your message has been sent successfully!");
    // Reset form
    setFormData({
      name: "",
      companyName: "",
      email: "",
      number: "",
      message: "",
      agreement: false,
    });
    setFormTouched(false);
    setErrors({});
    setIsSubmitting(false); // Re-enable the button
    setTimeout(() => {
      setSuccessMessage("");
    }, 5000);
    captchaRef.current.reset();
    setVerified(false);
  };

  const sendEmail = async (data) => {
    // Simulate email processing
    return new Promise((resolve) => {
      setTimeout(() => {
        const emailBody = `
                Name: ${data.name}
                Company Name: ${data.companyName}
                Email: ${data.email}
                Phone Number: ${data.number}
                Message: ${data.message}
            `;
        console.log("Sending Email with data: ", emailBody); // Replace with email API logic
        // clearForm();
        resolve();
      }, 1000);
    });
  };

  return (
    <>
      <section
        className=" relative h-auto overflow-hidden flex items-start z-10 w-full bg-black lg:pt-20 pt-0"
        id="contact-form"
      >
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full mx-auto px-3">
          <div className="w-full flex lg:flex-row flex-col gap-3 justify-between ">
            <div className="lg:w-1/2 w-full bg-dark_brown lg:pt-5 pt-10 lg:px-5 px-5 rounded-2xl flex flex-col space-y-5 items-start contact-cta relative">
              <span className="px-3 py-1 border border-white rounded-xl text-sm">
                Start Saving Today
              </span>
              <h1 className="lg:text-3xl text-xl">Get in Touch!</h1>
              <p className="text-2xl">Join MRD to save $1000’s on your bill.</p>
              <a
                href="#contact-form"
                className="text-white bg-dark-red hover:bg-[#9a1c1f] rounded-xl text-sm font-normal px-3 lg:py-2 py-2 text-center"
              >
                Request a Demo
              </a>
              <div className="w-full">
                <img
                  src={
                    process.env.REACT_APP_BASE_URL +
                    `/assets/images/contact-cta.png`
                  }
                  alt="Piston Hero"
                  className="w-full"
                />
              </div>
            </div>
            <div className="lg:w-1/2 w-full bg-dark_brown lg:py-5 py-10 lg:px-5 px-5 rounded-2xl">
              <form
                className="md:mt-0 mt-2 space-y-3 lg:w-full"
                onSubmit={handleSubmit}
              >
                <div className="grid grid-cols-1">
                  <div className="w-full">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-base text-white"
                    >
                      Name
                    </label>
                    <input
                      id="name"
                      type="text"
                      className={`w-full sm:text-sm border ${
                        errors.name ? "border-red-500" : "border-dark-red/50"
                      } p-2 rounded-md bg-black focus:bg-black-form placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                      placeholder="Your Full Name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {formTouched && errors.name && (
                      <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1">
                  <div className="w-full">
                    <label
                      htmlFor="companyName"
                      className="block mb-2 text-base text-white"
                    >
                      Company Name
                    </label>
                    <input
                      id="companyName"
                      type="text"
                      className={`w-full sm:text-sm border ${
                        errors.companyName
                          ? "border-red-500"
                          : "border-dark-red/50"
                      } p-2 rounded-md bg-black focus:bg-black-form placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                      placeholder="Your Company Name"
                      value={formData.companyName}
                      onChange={handleInputChange}
                    />
                    {formTouched && errors.companyName && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-base text-white"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      type="text"
                      inputMode="email"
                      className={`w-full sm:text-sm border ${
                        errors.email ? "border-red-500" : "border-dark-red/50"
                      } p-2 rounded-md bg-black focus:bg-black-form placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                      placeholder="Your Email Address"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {formTouched && errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="number"
                      className="block mb-2 text-base text-white"
                    >
                      Phone Number
                    </label>
                    {/* <InputMask
                      id="number"
                      mask="(999) 999-9999"
                      placeholder="Your Phone Number"
                      className={`w-full sm:text-sm border ${
                        errors.number ? "border-red-500" : "border-dark-red/50"
                      } p-2 rounded-md bg-black focus:bg-black-form placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                      value={formData.number}
                      onChange={handleInputChange}
                      inputMode="numeric"
                      type="tel"
                      autoComplete="off"
                    /> */}
                    <input
                      id="number"
                      placeholder="Your Phone Number"
                      className={`w-full sm:text-sm border ${
                        errors.number ? "border-red-500" : "border-dark-red/50"
                      } p-2 rounded-md bg-black focus:bg-black-form placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                      value={formData.number}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        if (numericValue.length <= 10) {
                          setFormData({ ...formData, number: numericValue });
                        }
                      }}
                      inputMode="numeric"
                      type="tel"
                      autoComplete="off"
                      maxLength={10}
                    />
                    {formTouched && errors.number && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.number}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1">
                  <div className="w-full">
                    <label
                      htmlFor="companyName"
                      className="block mb-2 text-base text-white"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows="3"
                      className={`mt-3 md:mt-0 w-full sm:text-sm border ${
                        errors.message ? "border-red-500" : "border-dark-red/50"
                      } p-2 rounded-md bg-black focus:bg-black-form placeholder-gray-500 focus:outline-none focus:ring-0 ring-gray-600 ring-opacity-25 cursor-default caret-white text-white`}
                      placeholder="Your Message or Any Specific Questions"
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                    {formTouched && errors.message && (
                      <p className="text-red-500 text-sm">{errors.message}</p>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div class="flex items-start mb-5">
                    <input
                      id="agreement"
                      type="checkbox"
                      checked={formData.agreement}
                      className="w-4 h-4 mt-1 rounded-md accent-red-500"
                      onChange={handleInputChange}
                    />
                    <label
                      htmlFor="agreement"
                      className="ms-2 text-sm font-normal text-white text-opacity-50"
                    >
                      I agree to receive communications by text message about
                      the product From Piston. You may opt-out by replying STOP
                      or ask for more information by replying HELP. Message
                      frequency varies. Message and data rates may apply. You
                      may review our{" "}
                      <a
                        href="/terms-and-conditions"
                        target="_blank"
                        className="text-blue-500 underline"
                      >
                        Privacy Policy
                      </a>{" "}
                      to learn how your data is used.
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-1 recaptcha">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={() => setVerified(true)}
                    ref={captchaRef}
                  />

                  {formTouched && errors.recaptcha && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.recaptcha}
                    </p>
                  )}
                </div>

                <div className="grid grid-cols-1">
                  <div className="md:w-auto w-full">
                    <button
                      type="submit"
                      className="text-white bg-dark-red hover:bg-[#9a1c1f] rounded-xl text-sm font-normal px-3 lg:py-2 py-2"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Processing..." : "Contact our team"}
                    </button>
                  </div>
                </div>

                {successMessage && (
                  <p className="text-green-500 text-sm mt-3">
                    {successMessage}
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
