import React from "react";
import { motion } from "framer-motion";

const AboutUs = ({ title, index }) => {
  return (
    <>
      <section className="relative h-auto overflow-hidden flex items-start z-10 w-full bg-black mt-10">
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm w-full mx-auto px-3">
          <div className="w-full flex lg:flex-row flex-col gap-5 justify-between mx-auto rounded-3xl lg:space-y-10 space-y-5 border border-dark-red px-3">
            <div className="lg:w-2/5 md:w-1/2 w-full py-10 ps-10 flex flex-col items-start">
              <span className="text-white text-sm font-normal border border-dark-red rounded-xl lg:px-3 px-4 py-1 lg:mb-8 mb-4">
                A 100% Cardless Solution
              </span>
              <h1 className="lg:text-3xl text-xl">
                Leverage Piston's Cardless Solution to Drive Unparalleled
                Savings for You
              </h1>
              <a
                href="#contact-form"
                className="text-white bg-dark-red hover:bg-[#9a1c1f] rounded-xl text-sm font-normal px-4 lg:py-1.5 py-1.5 text-center lg:mt-auto mt-3"
              >
                Request a Demo
              </a>
            </div>
            <div className="lg:w-3/5 md:w-1/2 w-full lg:pt-10 pt-0 lg:pe-10 pe-0 lg:pb-0 pb-3">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.5 }}
                viewport={{ once: true }}
              >
                <img
                  src={
                    process.env.REACT_APP_BASE_URL +
                    `/assets/images/cardless-bg.png`
                  }
                  className="w-full"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
