import React from "react";
import ContactUs from "../../common-sections/ContactUs";
// import ContactUs from "../../common-sections/ContactUsOld";

const ContactView = () => {
  return (
    <>
      <section className="relative w-full py-10 overflow-hidden bg-black"></section>
      <ContactUs />
    </>
  );
};

export default ContactView;
